"use client";

// Core
import { ReactNode, Suspense } from "react";

import store, { StoreContext } from "@/client/store";

export const StoreProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense>
      <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    </Suspense>
  );
};
